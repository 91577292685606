<template>
  <div class="home">
    <h1>اختبار تدبر أجزاء القرآن الكريم</h1>
    <h3>من كتاب متعة التدبّر للدكتور إياد قنيبي</h3>
    <h4>(الطبعة الثالثة)</h4>
    <br />
    <img src="@/assets/tadabor-book-cover-400.webp" /> 
    <br/>
    
    <p>اختر رقم الجزء من الأعلى لتبدأ الاختبار</p>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    
  }
}
</script>

<style scoped>
img {
  max-width: 100%;
}
</style>